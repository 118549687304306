export const dictionaryOfContentType = {
  article: 'Artigo',
  ebook: 'E-book',
  infographics: 'Infográfico',
  video: 'Vídeo',
  research: 'Pesquisa',
  thematic_kit: 'Kits temáticos',
  magazine: 'Revista',
  spreadsheet: 'Ferramenta de gestão',
  podcast: 'Podcast',
  course: 'Curso',
  course_sync: 'Curso ao vivo',
  series: 'Série',
  exclusive: 'Exclusivo',
  live: 'Live',
};
