import { dealWithTitleChange } from '../utils';

export default function useCreateSlug() {
  const createSlug = (slugTyped: string) => {
    const slug = dealWithTitleChange(slugTyped);

    return slug;
  };

  return { createSlug };
}
