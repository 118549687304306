import { AddCircle } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import axios, { CancelToken } from 'axios';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { Option } from 'react-select/src/filters';
import Swal from 'sweetalert2';

import { DataOptionType } from '../../../common/types/DataOption';
import { modalWithoutSchema } from '../../../common/validations/modalWithoutSchema';
import Button from '../../../components/Button/index';
import CustomSelect from '../../../components/CustomSelect';
import DraftEditor from '../../../components/DraftEditor/DraftEditor';
import { FlexContainer } from '../../../components/FlexContainer';
import Categorization from '../../../components/FormComponent/Categorization';
import MetaTag from '../../../components/FormComponent/MetaTag';
import TextField from '../../../components/FormComponent/TextField';
import Loading from '../../../components/Loading';
import SambaMainFile from '../../../components/SambaMainFile';
import RegisterModal from '../../../components/SimpleModal/RegisterModal';
import UploadCover from '../../../components/UploadCover';
import { useCreateSlug } from '../../../hooks';
import {
  createCompanyUnit,
  getAllCompanyUnit,
} from '../../../services/company';
import { createRequest, getAllRequesters } from '../../../services/requester';
import theme from '../../../styles/theme';
import { dealWithTitleChange } from '../../../utils';
import * as S from './styles';

type SyncCourseStageOneProps = {
  view: boolean;
  content?: any;
  onSubmit: () => void;
  onCancel: () => void;
  getFunc: (id: string, cancelToken: CancelToken) => Promise<void>;
  isLoading: boolean;
  handleCloneState: React.Dispatch<React.SetStateAction<any[]>>;
};

type Item = {
  [key: string]: any;
};

type Key = 'company_units' | 'requester';

type KeyModalProps = 'companyUnits' | 'requester';

type OptionsToSelect = {
  company_units: Option[] | [];
  requesters: Option[] | [];
};

export default function SyncCourseStageOne({
  view,
  content,
  onSubmit,
  onCancel,
  getFunc,
  isLoading,
  handleCloneState,
}: SyncCourseStageOneProps) {
  const [openModal, setOpenModal] = useState({
    companyUnits: false,
    requester: false,
  });
  const [highlight, setHighlight] = useState(false);
  const [optionsToSelect, setOptionsToSelect] = useState<OptionsToSelect>({
    company_units: [],
    requesters: [],
  });

  const courseId = new URLSearchParams(location.search).get('id');

  const {
    setValue,
    watch,
    control,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { createSlug } = useCreateSlug();

  const warning = () => {
    Swal.fire({
      icon: 'warning',
      iconColor: theme.colors.red_400,
      title: 'Cuidado!',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      confirmButtonColor: theme.colors.default,
    });
  };

  const handleSelectChange = {
    'select-option': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    'remove-value': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    clear: (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
  };

  const handleMuiltSelected = <T extends Item>(item: T, key: Key) => {
    const keyIds = getValues(key);

    keyIds === undefined
      ? setValue(key, [{ label: item.label, value: item.value }])
      : setValue(key, [...keyIds, { label: item.label, value: item.value }]);
  };

  const handleSelected = <T extends Item>(item: T, key: Key) => {
    setValue(key, { label: item.label, value: item.value });
  };

  const handleOpenModal = (key: KeyModalProps) => {
    setOpenModal(prevState => ({ ...prevState, [key]: !openModal[key] }));
  };

  const getOptionsForSelect = async () => {
    const fetchData = async (getDataFunc, key, keyId) => {
      const data = await getDataFunc();

      if (!data[key].length) return [];

      return data[key].map(item => ({
        label: item['name'],
        value: item[keyId],
      }));
    };

    const options = await Promise.all([
      fetchData(getAllCompanyUnit, 'companies_unit', 'company_unit_id'),
      fetchData(getAllRequesters, 'requesters', 'requester_id'),
    ]);

    setOptionsToSelect({
      company_units: options[0],
      requesters: options[1],
    });
  };

  const handleHighlight = (bool: boolean) => {
    setValue('featured_start_at', null);
    setValue('featured_end_at', null);
    setHighlight(bool);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    getOptionsForSelect();

    if (courseId) {
      getFunc(courseId, source.token).then(() => {
        handleCloneState(state => {
          const arr = state;
          arr[0] = getValues();

          return arr;
        });
        setHighlight(
          !!getValues('featured_start_at') || !!getValues('featured_end_at'),
        );
      });
    }

    return () => {
      source.cancel('Requisição cancelada pelo componente desmontado.');
    };
  }, []);

  const handleTitleChange = e => {
    setValue('page_title', e.target.value);
    const slug = dealWithTitleChange(e.target.value);
    setValue('slug', slug);

    if (Object.keys(errors).includes('page_title')) {
      clearErrors('page_title');
      clearErrors('slug');
    }
  };

  const handleSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const slug = createSlug(e.currentTarget.value);

    setValue('slug', slug);
  };

  if (isLoading) return <Loading />;

  return (
    <S.Form>
      <FlexContainer gap="0.875rem" flexDirection="column">
        <FlexContainer gap="0.875rem" flexWrap="wrap" width="100%">
          <TextField
            label="Título Página"
            name="page_title"
            flex="1 0 13.75rem"
            maxLength={65}
            onChange={handleTitleChange}
            required
            disabled={view}
          />
          <TextField
            label="Slug | URL"
            flex="1 0 13.75rem"
            name="slug"
            onClick={warning}
            onChange={handleSlug}
            required
            disabled={view}
          />
          <TextField
            label="Título"
            flex="1 0 13.75rem"
            name="title"
            maxLength={70}
            required
            disabled={view}
          />
          <TextField
            flex="1 0 13.75rem"
            label="Subtítulo"
            name="subtitle"
            required
            disabled={view}
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
          <TextField
            label="Resumo"
            name="resume"
            required
            disabled={view}
            flex="1 0 auto"
          />
          <TextField
            label="Descrição"
            name="description"
            required
            disabled={view}
            flex="1 0 13.75rem"
          >
            <DraftEditor
              name={'description'}
              setValue={setValue}
              value={watch('description')}
            />
          </TextField>
          <TextField
            label="Capa (Tamanho 1232 x 654px)"
            name="image_file_id"
            flex="1 0 auto"
          >
            <UploadCover
              isView={view}
              sizes={{
                width: 1232,
                heigth: 654,
              }}
              showCategory
            />
          </TextField>
          <FlexContainer gap="0.875rem" flexWrap="wrap-reverse" width="100%">
            <TextField label="Destaque" name="highlight" direction="row">
              {/* TODO: REFAZER O CHECKBOX */}
              <M.Checkbox
                id="highlight"
                checked={highlight}
                onChange={event => handleHighlight(event.target.checked)}
                disabled={view}
              />
            </TextField>

            <TextField
              label="Dias para conclusão"
              name="days_to_complete"
              disabled={view}
              type="number"
              defaultValue={1}
              required
            />
          </FlexContainer>
          {highlight && (
            <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
              <TextField
                label="Data Início Destaque"
                name="featured_start_at"
                flex="1 0 15rem"
              >
                <DatePicker
                  disabled={view}
                  value={watch('featured_start_at')}
                  onChange={date => setValue('featured_start_at', date)}
                  renderInput={params => <M.TextField {...params} />}
                />
              </TextField>
              <TextField
                label="Data Fim Destaque"
                name="featured_end_at"
                flex="1 0 15rem"
              >
                <DatePicker
                  disabled={view}
                  value={watch('featured_end_at')}
                  onChange={date => setValue('featured_end_at', date)}
                  renderInput={params => <M.TextField {...params} />}
                />
              </TextField>
            </FlexContainer>
          )}
          <FlexContainer gap="0.875rem" flexWrap="wrap" width="100%">
            <TextField
              label="Unidade Solicitante"
              name="company_units"
              flex="1 0 13.75rem"
              required
            >
              <CustomSelect
                id="company_units"
                isMulti
                isClearable
                isRtl={false}
                isSearchable
                options={optionsToSelect.company_units}
                onChange={(values, action) => {
                  handleSelectChange[action.action]('company_units', values);
                }}
                value={watch('company_units')}
                placeholder="Selecione as unidades"
                thereIsError={Object.keys(errors).includes('company_units')}
                isDisabled={view}
              />
              <div>
                <Button
                  type="button"
                  startIcon={<AddCircle />}
                  variant="contained"
                  onClick={() => handleOpenModal('companyUnits')}
                  disabled={view}
                >
                  Adicionar novo
                </Button>
              </div>
            </TextField>
            <TextField
              label="Nome Solicitante "
              name="requester"
              flex="1 0 13.75rem"
              required
            >
              <CustomSelect
                id="requester"
                isClearable
                isRtl={false}
                isSearchable
                options={optionsToSelect.requesters}
                onChange={(value, action) => {
                  handleSelectChange[action.action]('requester', value);
                  value && setValue('requester_id', value.value);
                }}
                value={optionsToSelect.requesters.find(
                  requester => requester.value === watch('requester_id'),
                )}
                placeholder="Selecione o solicitante"
                thereIsError={Object.keys(errors).includes('requester')}
                isDisabled={view}
              />
              <div>
                <Button
                  type="button"
                  startIcon={<AddCircle />}
                  variant="contained"
                  onClick={() => handleOpenModal('requester')}
                  disabled={view}
                >
                  Adicionar novo
                </Button>
              </div>
            </TextField>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
          {/* TODO:REFAZER Categorization */}
          <Categorization
            content={content}
            control={control}
            setValue={setValue}
            view={view}
          />
          {/* TODO: REFAZER O MetaTag */}
          <MetaTag
            control={control}
            setValue={setValue}
            content={content}
            view={view}
          />
          {/* TODO: PADRONIZAR OS ESTILOS DO SambaMainFile */}
          <SambaMainFile
            setValue={setValue}
            control={control}
            content={content}
            view={view}
            type={'sync-course'}
            typeDuration={'sync-course'}
            getValues={getValues}
            title="Seletor Vídeo Teaser - Apresentação "
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer gap="0.875rem" flexWrap="wrap" flexDirection="row-reverse">
        <Button type="button" variant="contained" onClick={() => onSubmit()}>
          Salvar e Avançar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      </FlexContainer>

      {openModal.companyUnits &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.companyUnits}
            onCloseChange={() => handleOpenModal('companyUnits')}
            keyId={'company_unit_id'}
            funcCreate={createCompanyUnit}
            handleOptionChange={data =>
              handleMuiltSelected(data, 'company_units')
            }
            needSlug={false}
          />,
          document.body,
        )}
      {openModal.requester &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.requester}
            onCloseChange={() => handleOpenModal('requester')}
            keyId={'requester_id'}
            funcCreate={createRequest}
            handleOptionChange={(data: DataOptionType) => {
              const requesters = [...optionsToSelect.requesters];
              requesters.push({
                label: data.label,
                value: data.value,
                data: data.data,
              });

              handleSelected(data, 'requester');
              setValue('requester_id', data.value);
              setOptionsToSelect(prev => ({ ...prev, requesters }));
            }}
            needSlug={false}
          />,
          document.body,
        )}
    </S.Form>
  );
}
