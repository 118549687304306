import { zodResolver } from '@hookform/resolvers/zod';
import { isAfter, isBefore } from 'date-fns';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { LiveContentSchema } from '../../../../common/validations/liveValidation';
import { convertTimeToMilliseconds } from '../../../../helpers/convertTimeToMilliseconds';
import { useContentSubmit } from '../../../../hooks';
import { createContent } from '../../../../services/contents';
import { getSouthDate } from '../../../../services/courses';
import LiveStreamTemplate from '../../../../templates/LiveStreamTemplate';

export type LiveFormValues = z.infer<typeof LiveContentSchema>;

export const LIVE_INITAL_VALUES = {
  authors: [],
  categories: [],
  thumbnail_file_id: '',
  duration: 0,
  data_sul: {
    duration: '00:00',
  },
};

export default function LiveStreamCreate() {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<LiveFormValues>({
    resolver: zodResolver(LiveContentSchema),
    defaultValues: LIVE_INITAL_VALUES,
  });
  const navigate = useNavigate();

  const handleClass = async (code: string) => {
    setIsLoading(true);

    try {
      const { setValue, setError } = methods;
      const data = await getSouthDate(code);

      if (data.message) {
        setError('sebrae_code', { message: 'Código da turma inválida' });
        toast({
          message: data.message,
          type: 'warning',
        });
        return;
      }

      if (data !== undefined) {
        const duration = convertTimeToMilliseconds(data.duration.toString());

        setValue('duration', duration);
        setValue('data_sul.cod_data', data.code);
        setValue('data_sul.descricao', data.methodology?.descricao);
        setValue('data_sul.duration', data.duration);
        setValue('data_sul.id', data.id);
        setValue('data_sul.name', data.name);
        setValue('data_sul.price', data.price);
        setValue('data_sul.publico_alvo', data.methodology?.publico_alvo);
        setValue('data_sul.status', data.status);
        setValue('data_sul.vacancies', data.vacancies);
      }
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações do curso no Barramento!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleErrorDate = (start: Date, finish: Date) => {
    const { setError } = methods;

    if (isAfter(start, finish)) {
      setError('event.start_at', {
        message: 'A data inicío não pode ser anterior a data fim',
      });
      return true;
    }

    if (isBefore(finish, start)) {
      setError('event.finish_at', {
        message: 'A data fim não pode ser anterior a data inicío',
      });
      return true;
    }

    return false;
  };

  const submit = (data: LiveFormValues, status: ContentStatusType) => {
    const { obj } = useContentSubmit({ data });
    const event = obj['event'] as { start_at: Date; finish_at: Date };

    if (handleErrorDate(event.start_at, event.finish_at)) {
      Swal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        iconColor: '#f5365c',
        text: 'Os campos com a data inicío e fim estão incorretos',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
      return;
    }

    delete obj['thumbnail_file'];
    delete obj['thumbnail_tooltip'];
    delete obj['data_sul'];

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'live';
    obj['description'] = data['resume'];
    obj['review_in'] = data['event'].finish_at;

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Transmissão criada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <LiveStreamTemplate
        title="Criação de uma transmissão ao vivo"
        view={false}
        submit={submit}
        getClass={handleClass}
        isLoading={isLoading}
      />
    </FormProvider>
  );
}
