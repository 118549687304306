import React from 'react';

import { FlexContainer } from '../../FlexContainer';
import TextField from '../TextField';

export default function FormProductDataSul() {
  return (
    <FlexContainer gap="0.875rem" flexWrap="wrap">
      <TextField
        label="Código do evento no barramento"
        name="data_sul.id"
        disabled
      />
      <TextField
        label="Código da turma no datasul"
        name="data_sul.cod_data"
        disabled
      />
      <TextField label="Nome do produto" name="data_sul.name" disabled />
      <TextField label="Status do produto" name="data_sul.status" disabled />
      <TextField label="Preço" name="data_sul.price" disabled />
      <TextField label="Vagas" name="data_sul.vacancies" disabled />
      <TextField label="Duração" name="data_sul.duration" disabled />
      <TextField
        label="Descrição do produto"
        name="data_sul.descricao"
        disabled
      />
      <TextField label="Público-Alvo" name="data_sul.publico_alvo" disabled />
    </FlexContainer>
  );
}
