import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IEvent } from '../../../../models/content';
import { getContentById } from '../../../../services/contents';
import { getEventById } from '../../../../services/live';
import LiveEventTemplate from '../../../../templates/LiveEventTemplate';

export default function LiveEvent() {
  const [event, setEvent] = useState<IEvent>({} as IEvent);
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get('id');

  const getEvent = async (eventId: string | undefined) => {
    if (!eventId) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Evento não encontrado.',
        html: `
          <div>
            <p>
              Parece que o evento que você está procurando não existe ou foi
              removido.
            </p>
            <ul style="margin: 16px 0; padding-left: 20px; text-align: start">
              <li>Verifique se as informações estão corretas.</li>
              <li>Se precisar de ajuda, entre em contato com o suporte.</li>
            </ul>
          </div>`,
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/live/live-stream'));
      return;
    }

    try {
      const response = await getEventById(eventId);
      setEvent(response);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o evento!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getContent = async (id: string | null) => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/live/live-stream'));
      return;
    }

    setLoading(true);

    try {
      const content = await getContentById(id);

      return content;
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/live/live-stream'));
    }
  };

  useEffect(() => {
    getContent(id).then(content => getEvent(content?.event?.event_id));
  }, []);

  return <LiveEventTemplate event={event} isLoading={loading} />;
}
