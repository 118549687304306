import { z } from 'zod';

import { errorMessages } from '../../constants/errorMessages';

export const consumptionSchema = z.object({
  carga_horaria: z
    .string({ required_error: errorMessages.required })
    .regex(/([0-9])/),
  cod_acao: z.string({ required_error: errorMessages.required }),
  code_integration: z.string({ required_error: errorMessages.required }),
  cod_projeto: z.string({ required_error: errorMessages.required }),
  company: z.string().length(18, 'CNPJ inválido').optional(),
  cpf_responsavel: z.string({ required_error: errorMessages.required }),
  credential: z.string({ required_error: errorMessages.required }),
  customer: z.string().optional(),
  date_hour_end: z.date({ required_error: errorMessages.required }),
  description: z.string({ required_error: errorMessages.required }),
  instrumento: z.string({ required_error: errorMessages.required }),
  nome_realizacao: z.string({ required_error: errorMessages.required }),
  origin_id: z
    .string({ required_error: errorMessages.required })
    .regex(/([0-9])/),
  title: z.string({ required_error: errorMessages.required }),
  type: z.string({ required_error: errorMessages.required }),
  theme_id: z.string({ required_error: errorMessages.required }),
  date_hour_start: z.date({ required_error: errorMessages.required }),
});
