import httpClient from '../http-client';
import { contentHistory, IContent, IContentParams } from '../models/content';
import ContentFromReponse from '../models/from-api-response/content';
import { uploadFile } from './files';

type MetaDataProps = {
  total: number;
  limit: number;
  filters: any;
};

export const getContent = async (contentType: string) => {
  const content = (await httpClient.get(`/contents`)).data;

  return content;
};

export const createContent = async (newContent: any) => {
  const createContentResponse = await httpClient.post<ContentFromReponse>(
    `/content`,
    newContent,
  );
  return createContentResponse;
};

export const getAllContents = async (filters?: any) => {
  const contents = (await httpClient.get<any[]>('/content/admin/list')).data;

  return contents;
};

export const updateContent = async (payload: any) => {
  const response = await httpClient
    .put(`content`, payload)
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.response.data.message);
    });

  return response;
};

export const duplicateContent = async (contentId: string) => {
  await httpClient.post(`/content/duplicate`, { content_id: contentId });
};

export const deleteContent = async (contentId: string) => {
  await httpClient.delete(`content/${contentId}`);
};

export const changeContentThumbnail = async (
  contentId: string,
  formData: FormData,
) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`contents/${contentId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

export const changeContentBanner = async (
  contentId: string,
  formData: FormData,
) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`contents/${contentId}/banner`, {
    banner: uploadedFile.reference,
  });
};

export const activateOrInactivateContent = async (
  contentId: string,
  activate: boolean,
) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

export const getContentsList = async () => {
  const contentList = (
    await httpClient.get<any[]>(`/content/admin/list?status=published`)
  ).data;

  return contentList;
};

export const getContentsListEpisode = async (params?: any) => {
  const contentList = (
    await httpClient.get<any[]>(`/content/admin/list-episode`, { params })
  ).data;

  return contentList;
};

export const getContentsListFilter = async type => {
  const contentList = (
    await httpClient.get<any[]>(
      `/content/admin/list?status=published&types[]=${type}`,
    )
  ).data;

  return contentList;
};

export const getContentsEditHistory = async (contentId: string) => {
  const response = (
    await httpClient.get<contentHistory>(`/contents/history/${contentId}`)
  ).data;

  return response;
};

export const getAdminContentList = async params => {
  const contentList = (
    await httpClient.get<{ data: IContent[]; metaData: MetaDataProps }>(
      `/content/admin/export`,
      {
        params: { ...params },
      },
    )
  ).data;

  return contentList;
};

export const listContents = async (params?: IContentParams) => {
  const contents = (
    await httpClient.get<{
      data: ContentFromReponse[];
      metaData: MetaDataProps;
    }>('/content/admin/list', {
      params,
    })
  ).data;

  return contents;
};

export const getContentById = async (id: string): Promise<IContent> => {
  const content = await httpClient
    .get(`content/${id}`)
    .then(resp => resp.data)
    .catch(function (error) {
      if (error.response.status === 404) {
        throw new Error('Rota ou curso não existe!');
      }

      throw new Error(error.response.data.message);
    });

  return content;
};
