import React, { InputHTMLAttributes } from 'react';
import {
  FieldPathValue,
  InternalFieldName,
  Message,
  useFormContext,
  Validate,
  ValidationRule,
} from 'react-hook-form';

import theme from '../../../styles/theme';
import Input from '../Input';
import * as S from './styles';

export type TextFieldProps = {
  label?: string;
  name: string;
  flex?: string;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  children?: React.ReactNode;
  color?: keyof typeof theme.colors;
  options?:
    | Partial<{
        required: Message | ValidationRule<boolean>;
        min: ValidationRule<number | string>;
        max: ValidationRule<number | string>;
        maxLength: ValidationRule<number>;
        minLength: ValidationRule<number>;
        pattern: ValidationRule<RegExp>;
        validate:
          | Validate<FieldPathValue<any, any>>
          | Record<string, Validate<FieldPathValue<any, any>>>;
        valueAsNumber: boolean;
        valueAsDate: boolean;
        value: FieldPathValue<any, any>;
        setValueAs: (value: any) => any;
        shouldUnregister?: boolean;
        onChange?: (event: any) => void;
        onBlur?: (event: any) => void;
        disabled: boolean;
        deps: InternalFieldName | InternalFieldName[];
      }>
    | undefined;
} & InputHTMLAttributes<HTMLInputElement> &
  Pick<S.WrapperProps, 'direction'>;

type WithIconProps = Partial<TextFieldProps>;

function WithIcon({
  endIcon,
  startIcon,
  name,
  required,
  color,
  label,
  options,
  ...props
}: WithIconProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const isError = Object.keys(errors).includes(name!);

  return (
    <S.Wrapper direction="row" withIcon thereIsError={isError}>
      <S.Container thereIsError={isError}>
        {startIcon && startIcon}
        <S.Label htmlFor={name} required={required} color={color}>
          {label}
        </S.Label>
        {endIcon && endIcon}
      </S.Container>
      <Input
        thereIsError={isError}
        id={name}
        type={props.type || 'text'}
        register={{ ...register(name!, options!) }}
        {...props}
        withIcon
      />
      {errors[name!] && (
        <S.InvalidFeedback>{errors[name!].message}</S.InvalidFeedback>
      )}
    </S.Wrapper>
  );
}

export default function TextField({
  label,
  name,
  options,
  required,
  children,
  direction,
  color,
  endIcon,
  startIcon,
  flex,
  ...props
}: TextFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = (errors: any, name: string): boolean => {
    const path = name.split('.');
    let current = errors;

    for (const key of path) {
      if (!current[key]) {
        return false;
      }

      current = current[key];
    }

    return !!current.message;
  };

  const isError = hasError(errors, name);
  const componentProps = {
    label,
    name,
    options,
    required,
    children,
    direction,
    color,
    endIcon,
    startIcon,
    flex,
    ...props,
  };

  const handleErrorMessage = () => {
    const message = name.split('.').reduce((acc, part) => {
      if (acc[part]?.message === undefined) return acc[part];

      return acc[part].message;
    }, errors);

    return <S.InvalidFeedback>{message}</S.InvalidFeedback>;
  };

  return (
    <>
      {endIcon || startIcon ? (
        <WithIcon {...componentProps} />
      ) : (
        <S.Wrapper direction={direction} flex={flex} standard>
          <S.Label htmlFor={name} required={required} color={color}>
            {label}
          </S.Label>
          {!children ? (
            <Input
              thereIsError={isError}
              id={name}
              type={props.type || 'text'}
              standard
              register={{ ...register(name, options) }}
              {...props}
            />
          ) : (
            children
          )}
          {isError && (
            <S.InvalidFeedback>{handleErrorMessage()}</S.InvalidFeedback>
          )}
        </S.Wrapper>
      )}
    </>
  );
}
