import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import CharacterTotal from '../../components/CharacterTotal';
import DraftEditor from '../../components/DraftEditor/DraftEditor';
import { FlexContainer } from '../../components/FlexContainer';
import Authors from '../../components/FormComponent/Authors';
import Card from '../../components/FormComponent/Card';
import Categorization from '../../components/FormComponent/Categorization';
import ContentList from '../../components/FormComponent/ContentList';
import Form from '../../components/FormComponent/Form';
import TextField from '../../components/FormComponent/TextField';
import Loading from '../../components/Loading';
import UploadCover from '../../components/UploadCover';
import compareDate from '../../helpers/compareDate';
import { useCreateSlug } from '../../hooks';
import { ICollection } from '../../models/collection';
import { dealWithTitleChange, onCancel, onError, warning } from '../../utils';

type CollectionTemplateProps = {
  title: string;
  isLoading?: boolean;
  view: boolean;
  content?: ICollection;
  submit: (data) => void;
};

export default function CollectionTemplate({
  title,
  isLoading,
  view,
  content,
  submit,
}: CollectionTemplateProps) {
  const [highlight, setHighlight] = useState(false);

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
    control,
    handleSubmit,
  } = useFormContext();
  const navigate = useNavigate();
  const { createSlug } = useCreateSlug();

  const characterCount = watch('resume')?.length || 0;

  const limit = {
    sucess: 75,
    warning: 75,
    error: 80,
  };

  const handleTitleChange = e => {
    setValue('page_title', e.target.value);
    const slug = dealWithTitleChange(e.target.value);
    setValue('slug', slug);

    if (Object.keys(errors).includes('page_title')) {
      clearErrors('page_title');
      clearErrors('slug');
    }
  };

  const handleSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const slug = createSlug(e.currentTarget.value);

    setValue('slug', slug);
  };

  const handleHighlight = (bool: boolean) => {
    setValue('featured_start_at', null);
    setValue('featured_end_at', null);
    setHighlight(bool);
  };

  useEffect(() => {
    setHighlight(
      !!getValues('featured_start_at') || !!getValues('featured_end_at'),
    );
  }, [getValues('featured_start_at'), getValues('featured_end_at')]);

  if (isLoading) return <Loading />;

  return (
    <Card title={title}>
      <Form>
        <FlexContainer gap="0.875rem">
          <TextField
            label="Nome"
            name="name"
            onChange={handleTitleChange}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Slug | URL"
            name="slug"
            onClick={warning}
            onChange={handleSlug}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column">
          <TextField label="Resumo" name="resume" maxLength={80} required />

          <CharacterTotal characterCount={characterCount} limit={limit} />

          <TextField label="Descrição" name="description" required>
            <DraftEditor
              name={'description'}
              setValue={setValue}
              value={watch('description')}
            />
          </TextField>

          <TextField label="Capa (Tamanho 1283x282px)" name="image_file_id">
            <UploadCover
              isView={view}
              sizes={{
                width: 1283,
                heigth: 282,
              }}
            />
          </TextField>

          <TextField
            label="Miniatura (Tamanho 184x201px)"
            name="thumbnail_file_id"
          >
            <UploadCover
              isView={view}
              keyFileId="thumbnail_file_id"
              keyImageFile="thumbnail_file"
              keyImageName="thumbnail_name"
              keyImageTooltip="thumbnail_tooltip"
              sizes={{
                width: 184,
                heigth: 201,
              }}
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
          <TextField label="Destaque" name="highlight" direction="row">
            <M.Checkbox
              id="highlight"
              checked={highlight}
              onChange={event => handleHighlight(event.target.checked)}
              disabled={view}
            />
          </TextField>
        </FlexContainer>

        {highlight && (
          <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
            <TextField
              label="Data destaque início"
              name="featured_start_at"
              flex="1 0 15rem"
            >
              <DatePicker
                disabled={view}
                value={watch('featured_start_at') || null}
                onChange={date => setValue('featured_start_at', date)}
                renderInput={params => <M.TextField {...params} />}
              />
            </TextField>
            <TextField
              label="Data destaque fim"
              name="featured_end_at"
              flex="1 0 15rem"
              options={{
                validate: value => {
                  if (compareDate(getValues('featured_start_at'), value)) {
                    return '*A data de destaque fim não deve ser menor que a data de início. ';
                  } else {
                    true;
                  }
                },
              }}
            >
              <DatePicker
                disabled={view}
                value={watch('featured_end_at') || null}
                onChange={date => setValue('featured_end_at', date)}
                renderInput={params => <M.TextField {...params} />}
              />
            </TextField>
          </FlexContainer>
        )}

        <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
          <ContentList
            setValue={setValue}
            control={control}
            content={content?.contents}
          />
          <Authors
            control={control}
            setValue={setValue}
            view={view}
            content={content}
          />
          <Categorization
            control={control}
            setValue={setValue}
            content={content}
            view={view}
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap" justifyContent="center">
          <Button
            type="button"
            variant="contained"
            color="blue"
            onClick={() => handleSubmit(data => submit(data), onError)()}
            disabled={view}
          >
            Publicar
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() =>
              onCancel().then(isConfirmed => {
                if (isConfirmed) {
                  navigate(-1);
                }
              })
            }
            disabled={view}
            aria-disabled={view}
          >
            Cancelar
          </Button>
        </FlexContainer>
      </Form>
    </Card>
  );
}
