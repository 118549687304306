import { z } from 'zod';

import { errorMessages } from '../../constants/errorMessages';
import { categorySchema } from './categoryValidation';
import { optionSelectSchema } from './optionSelectValidation';
import { TagSchema } from './tagValidation';

export const LiveContentSchema = z
  .object({
    authors: z.array(z.string()),
    categories: z
      .array(z.union([z.string(), categorySchema]), {
        required_error: 'Este campo é obrigatório',
      })
      .refine(val => {
        const isArrayOfStrings = val.every(item => typeof item === 'string');
        const isArrayOfObjects = val.every(
          item => categorySchema.safeParse(item).success,
        );

        return isArrayOfStrings || isArrayOfObjects;
      }),
    company_units: z
      .array(optionSelectSchema, {
        required_error: 'Este campo é obrigatório',
      })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errorMessages.required,
          });
        }
      }),
    data_sul: z
      .object({
        descricao: z.string().optional(),
        cod_data: z.string().optional(),
        duration: z.string().optional(),
        id: z.number().optional(),
        name: z.string().optional(),
        price: z.number().optional(),
        publico_alvo: z.string().optional(),
        status: z.string().optional(),
        vacancies: z.number().optional(),
      })
      .optional(),
    duration: z.number({ required_error: errorMessages.required }),
    event: z.object({
      channel_id: z
        .string({ required_error: errorMessages.required })
        .nonempty(),
      finish_at: z.date({
        required_error: errorMessages.required,
        invalid_type_error: errorMessages.required,
      }),
      start_at: z.date({
        required_error: errorMessages.required,
        invalid_type_error: errorMessages.required,
      }),
      chat_url: z
        .string()
        .max(70, 'O resumo não poder conter mais que 70 caracteres')
        .nonempty(errorMessages.required),
    }),
    page_title: z
      .string()
      .nonempty(errorMessages.required)
      .max(55, 'O título da página não poder conter mais que 55 caracteres'),
    resume: z
      .string()
      .nonempty(errorMessages.required)
      .max(160, 'O resumo não poder conter mais que 160 caracteres'),
    requester_id: z.string().uuid().nonempty('Este campo é obrigatório'),
    requester: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: 'Este campo é obrigatório' },
    ),
    sebrae_code: z.string().nonempty(errorMessages.required),
    subtitle: z.string().nonempty(errorMessages.required),
    slug: z
      .string()
      .nonempty(errorMessages.required)
      .max(70, 'O slug da página não poder conter mais que 70 caracteres'),
    title: z.preprocess(value => {
      if (typeof value === 'string') {
        return value.replace(/<\/?span[^>]*>/g, '');
      }
      return value;
    }, z.string().nonempty(errorMessages.required).max(70, 'O título não pode conter mais que 70 caracteres')),
    tags: z.array(TagSchema, { required_error: errorMessages.required }),
    thumbnail_file_id: z.string().optional(),
    thumbnail_file: z.any().nullable().optional(),
    thumbnail_name: z.string().nullable().default(null).optional(),
    thumbnail_tooltip: z.string().nullable().default(null).optional(),
  })
  .superRefine((val, ctx) => {
    if (!val.authors.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessages.required,
        path: ['authors'],
      });
    }
  });
