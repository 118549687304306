import { z } from 'zod';

import { errorMessages } from '../../constants/errorMessages';
import { categorySchema } from './categoryValidation';

export const collectionSchema = z.object({
  authors: z.array(z.string()).optional(),
  contents: z
    .any()
    .array()
    .superRefine((val, ctx) => {
      if (val.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessages.required,
          path: ['contents'],
        });
      }
    }),
  description: z.string().optional(),
  categories: z
    .array(z.union([z.string(), categorySchema]), {
      required_error: 'Este campo é obrigatório',
    })
    .refine(val => {
      const isArrayOfStrings = val.every(item => typeof item === 'string');
      const isArrayOfObjects = val.every(
        item => categorySchema.safeParse(item).success,
      );

      return isArrayOfStrings || isArrayOfObjects;
    }),
  image_file: z.any().nullable().optional(),
  image_file_id: z.string().uuid().nullable().default(null).optional(),
  image_name: z.string().nullable().default(null).optional(),
  image_toltip: z.string().nullable().default(null).optional(),
  featured_start_at: z.date().nullable().optional(),
  featured_end_at: z.date().nullable().optional(),
  name: z
    .string()
    .max(50, 'O nome não poder conter mais que 50 caracteres')
    .nonempty(errorMessages.required),
  resume: z
    .string()
    .nonempty('Este campo é obrigatório')
    .max(80, 'O resumo não poder conter mais que 80 caracteres'),
  slug: z
    .string()
    .max(60, 'O slug não poder conter mais que 60 caracteres')
    .nonempty(errorMessages.required),
  thumbnail_file_id: z.string().nullable().default(null).optional(),
  thumbnail_file: z.any().nullable().optional(),
  thumbnail_name: z.string().nullable().default(null).optional(),
  thumbnail_tooltip: z.string().nullable().default(null).optional(),
});
