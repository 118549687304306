export const errorMessages = {
  required: 'Este campo é obrigatório.',
  dateStartAfterEnd:
    'A data de início não pode ser posterior à data de término. Ajuste as datas para prosseguir.',
  dateEndBeforeStart:
    'A data de término não pode ser anterior à data de início. Verifique e ajuste as datas para continuar.',
  dateStartBeforeToday:
    'A data de início não pode ser anterior ao momento atual. Escolha uma data e hora válidas.',
  dateEndBeforeToday:
    'A data de término não pode ser anterior ao momento atual. Por favor, selecione uma data e hora válidas.',
};
