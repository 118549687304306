import {
  DeleteOutlineOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import SearchComponents from '../../../components/SearchComponents';
import { TableProps } from '../../../components/Table';
import { searchNamePlaceholder } from '../../../constants/placeHolder';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import {
  deleteCollection,
  getAllCollections,
} from '../../../services/collections';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

const CollectionPage: React.FC = () => {
  const [collections, setCollections] = useState([] as any[]);
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderBySort, setOrderBySort] = useState('DESC');

  const [filters, setFilters] = useState<{ search: string } | undefined>();

  const navigate = useNavigate();
  const {
    paginationModel,
    rowCount,
    setPaginationModel,
    setRowCount,
    changePage,
    changePageSize,
  } = useTablePagination();
  const { getVariedContents, loading } = useGetContent(getAllCollections);
  const { handleParams } = useSearchContents();

  const showCollection = useCallback(
    (collectionId: string) => {
      navigate(`/registration/collections/edit?id=${collectionId}&view=true`);
    },
    [navigate],
  );

  const editCollection = useCallback(
    (collectionId: string) => {
      navigate(`/registration/collections/edit?id=${collectionId}`);
    },
    [navigate],
  );

  const removeCollection = useCallback(async collection => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html:
        'Você tem certeza que deseja remover o item ' +
        collection.name +
        ' selecionado?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteCollection(collection.collection_id);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluído com sucesso!',
          });

          await getVariedContents(setCollections, setRowCount, {
            limit: paginationModel.pageSize,
            offset: 1,
            order_by: orderBy,
            order_by_sort: orderBySort,
          });
        } catch (error: any) {
          Swal.fire({
            title: error.response.data.message,
            icon: 'error',
          });
        }
      }
    });
  }, []);

  const columns = [
    {
      headerName: 'Nome',
      field: 'name',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues.value}</p>
      ),
    },
    {
      headerName: 'Qtd. de conteúdos vinculados',
      field: 'contents_count',
      minWidth: 280,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => <span>{cellValues.value}</span>,
    },
    {
      headerName: 'Criado em',
      field: 'created_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>{moment(cellValues.value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      headerName: 'Atualizado em',
      field: 'updated_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>{moment(cellValues.value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      headerName: 'Ações',
      field: 'actions',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => (
        <FlexContainer gap="0.4rem" flexWrap="wrap" justifyContent="flex-start">
          <Button
            onClick={() => removeCollection(cellValues.row)}
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
          />
          <Button
            onClick={() => editCollection(cellValues.id)}
            icon={<EditOutlined fontSize="inherit" />}
            color="secondary"
            title="Editar"
          />
          <Button
            onClick={() => showCollection(cellValues.id)}
            icon={<VisibilityOutlined fontSize="inherit" />}
            color="secondary"
            title="Visualizar"
          />
        </FlexContainer>
      ),
    },
  ];

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="register">Cadastros</span>,
    <span key="register-collection">Coleções</span>,
  ];

  const handleSearch = async filter => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      filter,
    );

    await getVariedContents(setCollections, setRowCount, params);
  };

  useEffect(() => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      { ...filters },
    );

    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    getVariedContents(setCollections, setRowCount, {
      limit: paginationModel.pageSize,
      offset: 1,
      order_by: orderBy,
      order_by_sort: orderBySort,
      ...params,
    });
  }, [paginationModel.pageSize, orderBy, orderBySort]);

  useEffect(() => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      { ...filters },
    );

    getVariedContents(setCollections, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by: orderBy,
      order_by_sort: orderBySort,
      ...params,
    });
  }, [paginationModel.page, orderBy, orderBySort]);

  const tableProps: TableProps = {
    columns: columns,
    rows: collections,
    keyId: 'collection_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
    disableColumnMenu: true,
  };

  return (
    <ContentTemplate
      key={'collection-table'}
      pageName="coleções"
      linkToCreatePage="/registration/collections/create"
      breadcrumbs={breadcrumbs}
      searchComponent={
        <SearchComponents
          handleSearch={filters => {
            setFilters(filters);
            handleSearch(filters);
            changePage(0);
          }}
          customPlaceholder={searchNamePlaceholder}
        />
      }
      {...tableProps}
    />
  );
};

export default CollectionPage;
