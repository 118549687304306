import { zodResolver } from '@hookform/resolvers/zod';
import { isAfter, isBefore, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { LiveContentSchema } from '../../../../common/validations/liveValidation';
import { errorMessages } from '../../../../constants/errorMessages';
import { convertTimeToMilliseconds } from '../../../../helpers/convertTimeToMilliseconds';
import { useContentSubmit } from '../../../../hooks';
import { IContent } from '../../../../models/content';
import { getContentById, updateContent } from '../../../../services/contents';
import { getSouthDate } from '../../../../services/courses';
import LiveStreamTemplate from '../../../../templates/LiveStreamTemplate';
import { LIVE_INITAL_VALUES, LiveFormValues } from '../Create';

export default function LiveStreamEdit() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IContent>();

  const [searchParams] = useSearchParams();
  const methods = useForm<LiveFormValues>({
    resolver: zodResolver(LiveContentSchema),
    defaultValues: LIVE_INITAL_VALUES,
  });
  const navigate = useNavigate();

  const id = searchParams.get('id');
  const isView = useMemo(() => !!searchParams.get('view'), [searchParams]);

  const handleClass = async (code: string) => {
    const { setValue, setError } = methods;
    setLoading(true);

    try {
      const data = await getSouthDate(code);

      if (data.message) {
        setError('sebrae_code', { message: 'Código da turma inválida' });
        toast({
          message: data.message,
          type: 'warning',
        });
        return;
      }

      if (data !== undefined) {
        const duration = convertTimeToMilliseconds(data.duration.toString());

        setValue('duration', duration);
        setValue('data_sul.cod_data', data.code);
        setValue('data_sul.descricao', data.descricao);
        setValue('data_sul.duration', data.duration);
        setValue('data_sul.id', data.id);
        setValue('data_sul.name', data.name);
        setValue('data_sul.price', data.price);
        setValue('data_sul.publico_alvo', data.publico_alvo);
        setValue('data_sul.status', data.status);
        setValue('data_sul.vacancies', data.vacancies);
      }
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações do curso no Barramento!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEventDate = (eventDate: string) => {
    const zoneDate = parseISO(eventDate);

    return zoneDate;
  };

  const getContent = async (id: string | null): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/live/live-stream'));
      return;
    }

    setLoading(true);

    try {
      const { setValue } = methods;
      const resp = await getContentById(id);

      if (
        resp.event?.start_at !== undefined &&
        resp.event?.finish_at !== undefined
      ) {
        handleErrorDate(
          new Date(resp.event?.start_at),
          new Date(resp.event?.finish_at),
        );
      }

      setValue(
        'authors',
        resp.authors.map(author => author.author_id),
      );
      setValue('event.chat_url', resp.event?.chat_url || '');
      setValue('event.channel_id', resp.event?.channel_id || '');
      setValue(
        'event.finish_at',
        !resp.event?.finish_at
          ? new Date()
          : handleEventDate(resp.event?.finish_at),
      );
      setValue(
        'event.start_at',
        !resp.event?.start_at
          ? new Date()
          : handleEventDate(resp.event?.start_at),
      );
      setValue('page_title', resp.page_title);
      setValue('resume', resp.resume);
      setValue('sebrae_code', resp.sebrae_code || '');
      setValue('slug', resp.slug);
      setValue('subtitle', resp.subtitle);
      setValue('tags', resp.tags);
      setValue('title', resp.title);
      setValue(
        'company_units',
        resp.company_units.map(company => ({
          label: company.name,
          value: company.company_unit_id,
        })),
      );
      setValue('requester', {
        label: resp.requester.name,
        value: resp.requester.requester_id,
      });
      setValue('requester_id', resp.requester_id);
      setValue('tags', resp.tags);
      setValue('categories', resp.categories);
      setValue('thumbnail_file_id', resp.thumbnail_file_id || '');
      setValue('thumbnail_file', resp.thumbnail_file);
      setValue('thumbnail_name', resp.thumbnail_name);
      setValue('thumbnail_tooltip', resp.thumbnail_tooltip);

      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      const code = methods.getValues('sebrae_code');
      handleClass(code);
      setLoading(false);
    }
  };

  const handleErrorDate = (start: Date, finish: Date) => {
    const { setError } = methods;

    if (isAfter(start, finish)) {
      setError('event.start_at', {
        message: 'A data inicío não pode ser anterior a data fim',
      });
      return true;
    }

    if (isBefore(finish, start)) {
      setError('event.finish_at', {
        message: 'A data fim não pode ser anterior a data inicío',
      });
      return true;
    }

    return false;
  };

  const handleCheckDate = () => {
    const { setError, getValues } = methods;

    const finishAt = getValues('event.finish_at');
    const startAt = getValues('event.start_at');
    const now = new Date();

    if (isAfter(now, finishAt)) {
      setError('event.finish_at', {
        message: errorMessages.dateEndBeforeToday,
      });
    }

    if (isAfter(now, startAt)) {
      setError('event.start_at', {
        message: errorMessages.dateStartBeforeToday,
      });
    }
  };

  const submit = (data: LiveFormValues, status: ContentStatusType) => {
    const { obj } = useContentSubmit({ data });
    const event = obj['event'] as { start_at: Date; finish_at: Date };

    if (handleErrorDate(event.start_at, event.finish_at)) {
      Swal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        iconColor: '#f5365c',
        text: 'Os campos com a data inicío e fim estão incorretos',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
      return;
    }

    delete obj['thumbnail_file'];
    delete obj['thumbnail_tooltip'];
    delete obj['data_sul'];

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'live';
    obj['description'] = data['resume'];
    obj['review_in'] = data['event'].finish_at;
    obj['content_id'] = id;
    const { channel_id, ...eventWithoutChannelId } = data.event;
    obj['event'] = eventWithoutChannelId;

    updateContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Transmissão editada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        console.error(error);

        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    getContent(id).then(handleCheckDate);
  }, []);

  return (
    <FormProvider {...methods}>
      <LiveStreamTemplate
        title="Edição de uma transmissão ao vivo"
        view={isView}
        submit={submit}
        isLoading={loading}
        content={content}
        getClass={handleClass}
      />
    </FormProvider>
  );
}
