import * as z from 'zod';

import { categorySchema } from './categoryValidation';
import { imageSchema } from './imageValidation';
import { knowledgesSchema } from './knowledgesValidation';
import { optionSelectSchema } from './optionSelectValidation';
import { TagSchema } from './tagValidation';
import { TopiSchema } from './topicValidation';

const meetingSchema = z.object({
  sebrae_id: z.string({ required_error: 'O id do encontro é obrigatório' }),
  start_at: z.date({ required_error: 'A data do encontro é obrigatório' }),
  finish_at: z.date({ required_error: 'A data do encontro é obrigatório' }),
});

export const classesSchema = z.object({
  name: z.string({ required_error: 'O nome da turma é obrigatório' }),
  description: z.string({
    required_error: 'A descrição da turma é obrigatória',
  }),
  vacancies: z.number({
    required_error: 'A quantidade de vagas da turma é obrigatória',
  }),
  price: z.number({ required_error: 'O preço da turma é obrigatório' }),
  duration: z.number({
    required_error: 'O tempo de duração da turma é obrigatório',
  }),
  start_at: z.date({
    required_error: 'A data de inicio da turma é obrigatória',
  }),
  finish_at: z.date({ required_error: 'A data fim da turma é obrigatória' }),
  sebrae_code: z.string({
    required_error: 'O código sebrae da turma é obrigatória',
  }),
  meetings: z.optional(z.array(meetingSchema)),
  instructor_cpf: z.string().nonempty(),
  instructor_slug: z.string().nonempty(),
  instructor_email: z.string().email().nonempty(),
  instructor_name: z.string().nonempty(),
  instructor_is_email_cpf_blocked: z.boolean(),
  status: z.string(),
  moderators: z.array(z.string()).optional(),
});

const statusShema = z
  .union([z.literal('published'), z.literal('draft'), z.literal('hidden')])
  .optional();

export const stageOneSchema = z.object({
  categories: z
    .array(z.union([z.string(), categorySchema]), {
      required_error: 'Este campo é obrigatório',
    })
    .refine(val => {
      const isArrayOfStrings = val.every(item => typeof item === 'string');
      const isArrayOfObjects = val.every(
        item => categorySchema.safeParse(item).success,
      );

      return isArrayOfStrings || isArrayOfObjects;
    }),
  content_id: z.string().nullable().optional(),
  company_units: z
    .array(optionSelectSchema, {
      required_error: 'Este campo é obrigatório',
    })
    .superRefine((val, ctx) => {
      if (!val.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Este campo é obrigatório',
        });
      }
    }),
  description: z.string().nonempty('Este campo é obrigatório'),
  days_to_complete: z
    .string({ required_error: 'Este campo é obrigatório' })
    .refine(value => Number(value) > 0, {
      message: 'Esse campo não aceita numero negativos',
    }),
  edition: z.string().nullable().default(null).optional(),
  extension: z.string().nullable().default(null).optional(),
  featured_start_at: z.date().nullable().default(null).optional(),
  featured_end_at: z.date().nullable().default(null).optional(),
  file_name: z.string().optional().nullable(),
  file_id: z.string().uuid().optional().nullable(),
  file_toltip: z.string().optional().nullable(),
  groups: z.array(z.string()).nullable().default(null).optional(),
  image_file: imageSchema.nullable().optional(),
  image_file_id: z.string().uuid().nullable().default(null).optional(),
  image_name: z.string().nullable().default(null).optional(),
  image_toltip: z.string().nullable().default(null).optional(),
  metatags: z.any().optional(),
  page_title: z
    .string()
    .nonempty('Este campo é obrigatório')
    .max(48, 'O título da página não poder conter mais que 48 caracteres'),
  part: z.string().nullable().default(null).optional(),
  published_start_at: z.date().optional().nullable(),
  published_end_at: z.date().optional().nullable(),
  reference: z.string().optional().nullable(),
  requester_id: z.string().uuid().nonempty('Este campo é obrigatório'),
  requester: z.object(
    {
      label: z.string(),
      value: z.string(),
    },
    { required_error: 'Este campo é obrigatório' },
  ),
  resume: z
    .string()
    .nonempty('Este campo é obrigatório')
    .max(155, 'O resumo não poder conter mais que 155 caracteres'),
  review_in: z.date().optional(),
  subtitle: z.string().nonempty('Este campo é obrigatório'),
  slug: z.string().nonempty('Este campo é obrigatório'),
  status: statusShema,
  title: z
    .string()
    .nonempty('Este campo é obrigatório')
    .max(70, 'O título não poder conter mais que 70 caracteres'),
  type: z.string().optional(),
});

export const stageTwoSchema = z.object({
  content_id: z.string().nullable().optional(),
  duration: z.string().nonempty('Este campo é obrigatório'),
  sebrae_code: z
    .string()
    .nonempty('O código da métodologia deve ser informado!'),
  status: statusShema,
  theme: z.string().optional(),
  price: z.string().nonempty('Este campo é obrigatório'),
  title: z.string().nonempty('Este campo é obrigatório'),
});

export const stageThreeSchema = z.object({
  content_id: z.string().nullable().optional(),
  complement_contents: z.array(z.string().uuid()).optional(),
  modules_sync: z.array(z.string()).superRefine((val, ctx) => {
    if (!val.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
      });
    }
  }),
  knowledges: z.array(knowledgesSchema).superRefine((val, ctx) => {
    if (!val.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
      });
    }
  }),
  status: statusShema,
});

export const stageFourSchema = z.object({
  content_id: z.string().nullable().optional(),
  status: statusShema,
  topics: z.array(TopiSchema).superRefine((val, ctx) => {
    if (!val.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
      });
    }
  }),
  tags: z.array(TagSchema).optional(),
});
