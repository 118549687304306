import { z } from 'zod';

import { errorMessages } from '../../constants/errorMessages';

export const courseConsumptionSchema = z
  .object({
    code_integration: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required),
    company: z.string().nullable().optional(),
    credential: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required),
    date_hour_start: z.date({
      required_error: errorMessages.required,
      invalid_type_error: errorMessages.required,
    }),
    momento_id: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required)
      .regex(/([0-9])/),
    nome_realizacao: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required),
    course_origin_id: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required)
      .regex(/([0-9])/),
    portifolio_disponibilizacao_id: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required)
      .regex(/([0-9])/),
    theme_id: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required)
      .regex(/([0-9])/),
    tipo_realizacao: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required),
    title: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required),
    type: z
      .string({ required_error: errorMessages.required })
      .nonempty(errorMessages.required),
  })
  .superRefine((arg, ctx) => {
    const { company } = arg;
    if (company) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'CNPJ inválido',
        path: ['company'],
      });
    }
  });
