import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import Loading from '../../../components/Loading';
import { getReports } from '../../../services/reports';
import GeneralReportTemplate from '../../../templates/ReportsTemplates/General';

export type ReportsType = {
  classUsersFinished: string;
  classUsersInProgress: string;
  classUsersNotCanceledCount: string;
  classUsersSubscribed: string;
  consumption: string;
  consumptionBus: string;
  consumptionIntegrationErrorNoResend: string;
  consumptionIntegrationErrorResend: string;
  consumptionValid: string;
  consumptionWithUser: string;
  contentUsersFinished: string;
  contentUsersInProgress: string;
  contentUsersSubscribed: string;
  subscribedButNotEnoughAttendance: string;
  users: string;
  usersWhoCanceledCount: string;
};
export default function General() {
  const [reports, setReports] = useState<ReportsType>({
    classUsersFinished: '0',
    classUsersInProgress: '0',
    classUsersNotCanceledCount: '0',
    classUsersSubscribed: '0',
    consumption: '0',
    consumptionBus: '0',
    consumptionIntegrationErrorNoResend: '0',
    consumptionIntegrationErrorResend: '0',
    consumptionValid: '0',
    consumptionWithUser: '0',
    contentUsersFinished: '0',
    contentUsersInProgress: '0',
    contentUsersSubscribed: '0',
    subscribedButNotEnoughAttendance: '0',
    users: '0',
    usersWhoCanceledCount: '0',
  });
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    try {
      setLoading(true);
      const localContents = await getReports();
      const reportObject: ReportsType = {} as ReportsType;

      for (const key in localContents) {
        reportObject[key] = localContents[key].toLocaleString('pt-BR');
      }

      setReports(reportObject);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o consumo!',
        html: error.message || error,
        confirmButtonColor: '#5e72e4',
        buttonsStyling: false,
        customClass: {
          htmlContainer: 'message-error-methodology modal-html-container',
          denyButton: 'btn-download-log',
          confirmButton: 'btn-ok',
          title: 'modal-title',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="reports">Relatórios</span>,
    <span key="report-general">Relatório Geral</span>,
  ];

  if (loading) return <Loading />;

  return <GeneralReportTemplate reports={reports} breadcrumbs={breadcrumbs} />;
}
