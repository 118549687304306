import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import { isAfter, isBefore } from 'date-fns';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';

import Button from '../../../components/Button';
import ContentSelector from '../../../components/ContentSelector';
import CustomSelect from '../../../components/CustomSelect';
import { FlexContainer } from '../../../components/FlexContainer';
import Form from '../../../components/FormComponent/Form';
import TextField from '../../../components/FormComponent/TextField';
import Heading from '../../../components/Heading';
import ContentsModal from '../../../components/SimpleModal/ContentsModal';
import Table, { TableProps } from '../../../components/Table';
import { useContentSelector } from '../../../hooks';
import { formatCpf } from '../../../utils';
import * as S from './styles';

type ConsumptionTemplateProps = {
  tableProps: TableProps;
  submit: (data) => Promise<void>;
  clearForm: () => void;
};

export default function ConsumptionTemplate({
  tableProps,
  submit,
  clearForm,
}: ConsumptionTemplateProps) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { handleContentSelected, handleRemoveSelected, handleSelectedContent } =
    useContentSelector({ setValue, getValues });

  const consumptionTypeOptions = [
    { label: 'Download', value: 'download' },
    { label: 'Player', value: 'player' },
    { label: 'Leitura', value: 'reading' },
    { label: 'Video Teaser de Curso', value: 'teaser' },
    { label: 'Finalizados de curso/serie', value: 'conclusion' },
    { label: 'Curso ao Vivo', value: 'course_sync' },
    { label: 'Live', value: 'live' },
  ];

  const consumptionProgressStatus = [
    { label: 'Inscrito', value: 'subscribed' },
    { label: 'Em progresso', value: 'in_progress' },
    { label: 'Em Progresso 25%', value: 'in_progress_25' },
    { label: 'Em Progresso 50%', value: 'in_progress_50' },
    { label: 'Em Progresso 75%', value: 'in_progress_75' },
    { label: 'Concluído', value: 'finished' },
  ];

  const userAcessTypeOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Logado', value: 'logged' },
    { label: 'Não Logado', value: 'not_logged' },
  ];

  const situationTypeOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Válidos', value: 'valid' },
    { label: 'Inválidos', value: 'invalid' },
    { label: 'Processando', value: 'processing' },
    { label: 'Válidos com Erro', value: 'valid_error' },
    { label: 'Válidos com Sucesso', value: 'valid_sucess' },
  ];

  const handleCreatedStartAt = (date: Date | null) => {
    if (date && isAfter(date, watch('created_end_at'))) {
      setError('created_start_at', {
        message:
          'A data publicação início não pode vir após a data publicação fim',
      });
      return;
    }

    if (Object.keys(errors).includes('created_start_at')) {
      clearErrors('created_start_at');
    }

    setValue('created_start_at', date);
  };

  const handleCreatedEndAt = (date: Date | null) => {
    if (date && isBefore(date, watch('created_start_at'))) {
      setError('created_end_at', {
        message:
          'A data publicação fim não pode ser anterior a data publicação início',
      });
      return;
    }

    if (Object.keys(errors).includes('created_end_at')) {
      clearErrors('created_end_at');
    }

    setValue('created_end_at', date);
  };

  const cpfInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCpf(e.target.value) || '';
  };

  return (
    <FlexContainer gap="2rem" flexDirection="column" width="100%" height="100%">
      <FlexContainer
        gap="2rem"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <S.Card>
          <S.CardHeader>
            <Heading size="medium" lineHeight={1.5}>
              Filtros
            </Heading>
          </S.CardHeader>

          <S.CardBody>
            <Form>
              <FlexContainer
                flex="1 1 auto"
                width="100%"
                gap="1rem"
                flexWrap="wrap"
              >
                <TextField
                  label="Buscar por Conteúdos"
                  name="contents"
                  flex="1 0 20.875rem"
                >
                  <ContentSelector
                    id="contents"
                    value={watch('contents') || []}
                    handleChange={handleSelectedContent}
                    handleOpenMenu={() => setIsOpen(!isOpen)}
                  />
                </TextField>
                <TextField
                  label="Buscar por CPF"
                  name="cpf"
                  flex="1 0 13rem"
                  maxLength={14}
                  onChange={cpfInputMask}
                  placeholder="000.000.000-00"
                />

                <TextField label="Tipo de Consumo" name="type" flex="1 0 17rem">
                  <CustomSelect
                    id="type"
                    isClearable
                    isRtl={false}
                    isSearchable
                    options={consumptionTypeOptions}
                    onChange={consumption => {
                      consumption
                        ? setValue('type', consumption.value)
                        : setValue('type', consumption);
                    }}
                    value={
                      consumptionTypeOptions.find(
                        type => type.value === watch('type'),
                      ) || null
                    }
                    placeholder="Selecione o tipo de consumo"
                  />
                </TextField>

                <TextField
                  label="Status de Progresso do Curso"
                  name="progress_status"
                  flex="1 0 17rem"
                >
                  <CustomSelect
                    id="progress_status"
                    isClearable
                    isRtl={false}
                    isSearchable
                    options={consumptionProgressStatus}
                    onChange={progress => {
                      progress
                        ? setValue('progress_status', progress.value)
                        : setValue('progress_status', progress);
                    }}
                    value={
                      consumptionProgressStatus.find(
                        progress => progress.value === watch('progress_status'),
                      ) || null
                    }
                    placeholder="Selecione o progresso do conteúdo"
                  />
                </TextField>
              </FlexContainer>

              <FlexContainer
                flex="1 1 auto"
                width="100%"
                gap="1rem"
                flexWrap="wrap"
              >
                <TextField
                  label="Tipo de Acesso do Usuário"
                  name="user_acess"
                  flex="1 0 13.75rem"
                >
                  <CustomSelect
                    id="user_acess"
                    isRtl={false}
                    isClearable={watch('user_acess') !== 'all'}
                    isSearchable
                    options={userAcessTypeOptions}
                    onChange={acess => {
                      acess === null
                        ? setValue('user_acess', 'all')
                        : setValue('user_acess', acess.value);
                    }}
                    value={userAcessTypeOptions.find(
                      item => item.value === watch('user_acess'),
                    )}
                    placeholder="Selecione o tipo de acesso"
                  />
                </TextField>

                <TextField
                  label="Situação do Consumo"
                  name="situation"
                  flex="1 0 13.75rem"
                >
                  <CustomSelect
                    id="situation"
                    isRtl={false}
                    isClearable={watch('situation') !== 'all'}
                    isSearchable
                    options={situationTypeOptions}
                    onChange={situation => {
                      situation === null
                        ? setValue('situation', 'all')
                        : setValue('situation', situation.value);
                    }}
                    value={situationTypeOptions.find(
                      item => item.value === watch('situation'),
                    )}
                    placeholder="Selecione a situação"
                  />
                </TextField>

                <TextField
                  label="Período Consumo (Data Início)"
                  name="created_start_at"
                  flex="1 0 10rem"
                >
                  <DatePicker
                    value={watch('created_start_at') || null}
                    onChange={handleCreatedStartAt}
                    renderInput={params => <M.TextField {...params} />}
                  />
                </TextField>

                <TextField
                  label="Período Consumo (Data Fim)"
                  name="created_end_at"
                  flex="1 0 10rem"
                >
                  <DatePicker
                    value={watch('created_end_at') || null}
                    onChange={handleCreatedEndAt}
                    renderInput={params => <M.TextField {...params} />}
                  />
                </TextField>
              </FlexContainer>

              <FlexContainer gap="1rem">
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => handleSubmit(submit)()}
                >
                  Filtrar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={clearForm}
                >
                  Limpar
                </Button>
              </FlexContainer>
            </Form>
          </S.CardBody>
        </S.Card>

        <Table {...tableProps} width="100%" height="100%" disableColumnMenu />
      </FlexContainer>

      {isOpen &&
        createPortal(
          <ContentsModal
            isOpen={isOpen}
            onCloseChange={() => setIsOpen(!isOpen)}
            handleSelected={handleContentSelected}
            selectedContent={watch('contents') || []}
            handleRemoveSelected={handleRemoveSelected}
          />,
          document.body,
        )}
    </FlexContainer>
  );
}
