import { Edit } from '@mui/icons-material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../components/Button';
import CustomRowsOverlay from '../../../components/CustomRowsOverlay';
import { FlexContainer } from '../../../components/FlexContainer';
import Pills from '../../../components/Pills';
import { dictionaryOfContentType } from '../../../constants/dictionaries';
import { useGetReport, useTablePagination } from '../../../hooks';
import { IListConsumption } from '../../../models/consumption';
import {
  getListConsumption,
  resendConsumption,
} from '../../../services/consumption';
import ConsumptionTemplate from '../../../templates/ReportsTemplates/ConsumptionTemplate';
import { formatCpf, removeTraitPoints } from '../../../utils';

export default function ConsumptionRecord() {
  const [consumptions, setConsumptions] = useState<IListConsumption[]>([]);
  const [selectionConsumption, setSelectionConsumption] = useState<string[]>();
  const [isProcessingFilter, setIsProcessingFilter] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const state = {
    user_acess: 'all',
    situation: 'all',
  };

  const methods = useForm({
    defaultValues: state,
  });

  const {
    paginationModel,
    rowCount,
    setRowCount,
    changePage,
    changePageSize,
    setPaginationModel,
  } = useTablePagination(25);

  const { listConsumption, loading } = useGetReport(getListConsumption);

  const consumptionTypes = {
    download: 'Download',
    player: 'Player',
    reading: 'Leitura',
    teaser: 'Video Teaser de Curs',
    conclusion: 'Finalizados de curso/serie',
    course_sync: 'Curso ao Vivo',
    live: 'Live',
  };

  const handleSituationColor = row => {
    switch (true) {
      case row.interaction && row.interaction_saved === false:
        return 'yellow';
      case row.interaction === false:
        return 'red';
      case row.interaction_saved:
        return 'green';
      default:
        return 'neutral';
    }
  };

  const resend = async (listConsumptionId: string[]) => {
    try {
      await resendConsumption(listConsumptionId).then(() => {
        toast.success('Consumo reenviado com sucesso!');
        methods.handleSubmit(data =>
          handlePagination(data, paginationModel.page),
        )();
        setSelectionConsumption([]);
      });
    } catch (error: any) {
      console.error(error);
      toast.error(error.message || error);
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 60,
      },
      {
        headerName: 'Data de Criação',
        field: 'created_at',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          format(new Date(row.created_at), 'dd/MM/yyyy'),
      },
      {
        headerName: 'Data de Reenvio/Edição',
        field: 'updated_at',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) =>
          format(new Date(row.created_at), 'dd/MM/yyyy'),
      },
      {
        headerName: 'CPF do Usuário',
        field: 'cpf',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const cpf = row.user ? formatCpf(row.user.cpf) : null;
          return <span title={cpf || undefined}>{cpf}</span>;
        },
      },
      {
        headerName: 'Título',
        field: 'title',
        flex: 2,
        sortable: false,
        renderCell: cellValues => (
          <p style={{ whiteSpace: 'normal' }}>
            {cellValues.row?.content.title}
          </p>
        ),
      },
      {
        headerName: 'Tipo Conteúdo',
        field: 'type_content',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => dictionaryOfContentType[row.content.type],
      },
      {
        headerName: 'Tipo Consumo',
        field: 'type',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => consumptionTypes[row.type],
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => {
          switch (true) {
            case row.interaction && row.interaction_saved === false:
              return 'Válido com erro';
            case row.interaction === false:
              return 'Inválido';
            case row.interaction_saved:
              return 'Válido com sucesso';
            default:
              return null;
          }
        },
        renderCell: cellValues => (
          <Pills color={handleSituationColor(cellValues.row)}>
            {cellValues?.value}
          </Pills>
        ),
      },
      {
        headerName: 'Mensagem de Erro',
        field: 'message',
        flex: 2,
        sortable: false,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Editar',
        field: 'edition',
        sortable: false,
        width: 65,
        renderCell: (cellValues: any) => (
          <FlexContainer gap="0.4rem" justifyContent="center">
            <Button
              onClick={() =>
                navigate(`/reports/consumption-record/edit`, {
                  state: {
                    consumption_id: cellValues.row['consumption_id'],
                    content_type: cellValues.row['content'].type,
                    filters: methods.getValues(),
                  },
                })
              }
              icon={<Edit fontSize="inherit" />}
              color="secondary"
              title="Editar consumo"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const handleResend = async () => {
    if (!selectionConsumption?.length) {
      toast.error(
        'É necessário selecionar pelo menos um consumo para reenviar',
      );
      return;
    }

    try {
      await resend(selectionConsumption);
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleData = data => {
    if (!data['cpf']) delete data['cpf'];

    if (data['cpf']) data['cpf'] = removeTraitPoints(data['cpf']);
    if (data['contents']?.length)
      data['contents'] = data['contents'].map(content => content.value);

    if (data['created_start_at'])
      data['created_start_at'] = data['created_start_at'].setHours(0, 0, 0, 0);
    if (data['created_end_at'])
      data['created_end_at'] = data['created_end_at'].setHours(23, 59, 59, 999);

    return data;
  };

  const submit = async data => {
    const obj = handleData(data);
    setIsProcessingFilter(data['situation'] !== 'valid_error');

    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    listConsumption(setConsumptions, setRowCount, {
      limit: paginationModel.pageSize,
      offset: 1,
      ...obj,
    });
  };

  const handlePagination = async (data, page) => {
    const obj = handleData(data);

    setPaginationModel(oldState => ({ ...oldState, page: page }));
    listConsumption(setConsumptions, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      ...obj,
    });
  };

  const handleClearForm = () => {
    methods.reset(state);
    location.state = null;

    setIsProcessingFilter(true);
    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    listConsumption(setConsumptions, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      ...state,
    });
  };

  const handleSelectionConsumption = (consumptionIds: GridSelectionModel) => {
    const listIds = consumptionIds.map(id => String(id));
    setSelectionConsumption(listIds);
  };

  const handleDisabledClick = () => {
    toast.error(
      'O botão está desabilitado. Para habilitar ele, mude o filtro "Situação do Consumo" para "Válido com Erro"',
    );
  };

  const CustomToolbar = () => {
    return (
      <FlexContainer
        style={{ padding: '10px 10px 0' }}
        flexWrap="wrap"
        gap="10px"
        justifyContent="flex-start"
      >
        {isProcessingFilter && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '170px',
              height: '41px',
              backgroundColor: 'transparent',
              cursor: 'not-allowed',
            }}
            onClick={handleDisabledClick}
          />
        )}
        <Button
          type="button"
          variant="contained"
          onClick={handleResend}
          color="default"
          disabled={isProcessingFilter}
        >
          Reenviar Consumo(s)
        </Button>
      </FlexContainer>
    );
  };

  const components = {
    NoRowsOverlay: () => {
      return <CustomRowsOverlay title="Nenhum consumo" />;
    },
    Toolbar: CustomToolbar,
  };

  const tableProps = {
    keyId: 'consumption_id',
    columns,
    rows: consumptions,
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [5, 10, 15, 20, 25],
    pageSize: paginationModel.pageSize,
    disableColumnReorder: true,
    rowCount,
    loading,
    components,
    checkboxSelection: true,
    keepNonExistentRowsSelected: true,
    selectionModel: selectionConsumption,
    onSelectionModelChange: handleSelectionConsumption,
  };

  useEffect(() => {
    const state = location.state as { filters: any };
    if (state) {
      methods.reset(state.filters);
      submit(state.filters);
    }
  }, []);

  useEffect(() => {
    methods.handleSubmit(data =>
      handlePagination(data, paginationModel.page),
    )();
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <FormProvider {...methods}>
      <ConsumptionTemplate
        submit={submit}
        clearForm={handleClearForm}
        tableProps={tableProps}
      />
    </FormProvider>
  );
}
