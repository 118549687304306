import { AddCircle } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { Option } from 'react-select/src/filters';
import Swal from 'sweetalert2';

import { KeyIds } from '../../common/enum/keyIds';
import { DataOptionType } from '../../common/types/DataOption';
import { modalWithoutSchema } from '../../common/validations/modalWithoutSchema';
import Button from '../../components/Button';
import CustomSelect from '../../components/CustomSelect';
import DraftEditor from '../../components/DraftEditor/DraftEditor';
import { FlexContainer } from '../../components/FlexContainer';
import Authors from '../../components/FormComponent/Authors';
import Card from '../../components/FormComponent/Card';
import Categorization from '../../components/FormComponent/Categorization';
import ContentList from '../../components/FormComponent/ContentList';
import Form from '../../components/FormComponent/Form';
import SeasonSection from '../../components/FormComponent/SeasonSection';
import TextField from '../../components/FormComponent/TextField';
import GenericDragAndDrop from '../../components/GenericDragAndDrop';
import Loading from '../../components/Loading';
import SambaMainFile from '../../components/SambaMainFile';
import RegisterModal from '../../components/SimpleModal/RegisterModal';
import UploadCover from '../../components/UploadCover';
import { useCreateSlug } from '../../hooks';
import { IContent } from '../../models/content';
import { createCompanyUnit, getAllCompanyUnit } from '../../services/company';
import { createknowledges, getAllknowledges } from '../../services/knowledges';
import { createRequest, getAllRequesters } from '../../services/requester';
import { createTag, getAllTags } from '../../services/tags';
import { createTopics, getAllTopics } from '../../services/topics';
import { dealWithTitleChange, groups } from '../../utils';
import { visibilities } from '../../utils/visibilitiesOptions';

type SerieTemplateProps = {
  title: string;
  isLoading?: boolean;
  view: boolean;
  content?: IContent;
  submit: (data, status) => void;
};

type OptionsToSelectType = {
  company_units: Option[] | [];
  requesters: Option[] | [];
};

type Item = {
  [key: string]: any;
};

type KeyModalType = 'companyUnits' | 'requester';

type Key = 'company_units' | 'requester';

export default function SerieTemplate({
  isLoading,
  title,
  view,
  content,
  submit,
}: SerieTemplateProps) {
  const [highlight, setHighlight] = useState(false);
  const [optionsToSelect, setOptionsToSelect] = useState<OptionsToSelectType>({
    company_units: [],
    requesters: [],
  });
  const [openModal, setOpenModal] = useState({
    companyUnits: false,
    requester: false,
  });

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
    control,
    handleSubmit,
  } = useFormContext();
  const navigate = useNavigate();
  const { createSlug } = useCreateSlug();

  const getOptionsForSelect = async () => {
    const fetchData = async (getDataFunc, key, keyId) => {
      const data = await getDataFunc();

      if (!data[key].length) return [];

      return data[key].map(item => ({
        label: item['name'],
        value: item[keyId],
      }));
    };

    const options = await Promise.all([
      fetchData(getAllCompanyUnit, 'companies_unit', 'company_unit_id'),
      fetchData(getAllRequesters, 'requesters', 'requester_id'),
    ]);

    setOptionsToSelect({
      company_units: options[0],
      requesters: options[1],
    });
  };

  useEffect(() => {
    const reviewIn = getValues('review_in');
    if (reviewIn === undefined) {
      setValue('review_in', null);
    }

    getOptionsForSelect();
  }, []);

  const handleTitleChange = e => {
    const slug = dealWithTitleChange(e.target.value);
    setValue('page_title', e.target.value);
    setValue('slug', slug);

    if (Object.keys(errors).includes('page_title')) {
      clearErrors('page_title');
      clearErrors('slug');
    }
  };

  const handleSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const slug = createSlug(e.currentTarget.value);

    setValue('slug', slug);
  };

  const handleHighlight = (bool: boolean) => {
    setValue('featured_start_at', null);
    setValue('featured_end_at', null);
    setHighlight(bool);
  };

  const handleSelectChange = {
    'select-option': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    'remove-value': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    clear: (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
  };

  const handleOpenModal = (key: KeyModalType) => {
    setOpenModal(prevState => ({ ...prevState, [key]: !openModal[key] }));
  };

  const handleMuiltSelected = <T extends Item>(item: T, key: Key) => {
    const keyIds = getValues(key);

    keyIds === undefined
      ? setValue(key, [{ label: item.label, value: item.value }])
      : setValue(key, [...keyIds, { label: item.label, value: item.value }]);
  };

  const handleSelected = <T extends Item>(item: T, key: Key) => {
    setValue(key, { label: item.label, value: item.value });
  };

  const warning = () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#f5365c',
      title: 'Erro',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      buttonsStyling: false,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
    });
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const onCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar as alterações?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  const verifyIfContentIsCreated = useMemo(() => {
    return !content?.content_id;
  }, [content]);

  if (isLoading) return <Loading />;

  return (
    <Card title={title}>
      <Form>
        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Título da página"
            name="page_title"
            disabled={view}
            flex="1 0 13.75rem"
            onChange={handleTitleChange}
            required
          />
          <TextField
            label="Slug | URL"
            name="slug"
            onClick={warning}
            onChange={handleSlug}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Título da série"
            name="title"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Subtítulo"
            name="subtitle"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column">
          <TextField
            label="Resumo"
            name="resume"
            disabled={view}
            flex="1"
            required
          />
          <TextField label="Descrição" name="description" required>
            <DraftEditor
              name={'description'}
              setValue={setValue}
              value={watch('description')}
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <GenericDragAndDrop
            view={view}
            keyId={KeyIds.KNOWLEDGE}
            createItens={createknowledges}
            getItens={getAllknowledges}
            name="knowledges"
            label="Conhecimentos que você vai adquirir"
            needSlug
            required
          />
          <GenericDragAndDrop
            view={view}
            keyId={KeyIds.TOPIC}
            createItens={createTopics}
            getItens={getAllTopics}
            name="topics"
            label="Tópicos"
            required
          />
          <GenericDragAndDrop
            view={view}
            keyId={KeyIds.TAGS}
            createItens={createTag}
            getItens={getAllTags}
            name="tags"
            label="Tags"
            needSlug
            required
          />
        </FlexContainer>

        <TextField label="Capa (Tamanho 1233 x 652px)" name="image_file_id">
          <UploadCover
            isView={view}
            sizes={{
              width: 1233,
              heigth: 652,
            }}
            showCategory
          />
        </TextField>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Visibilidade"
            name="visibility"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="visibility"
              isClearable
              isRtl={false}
              isSearchable
              options={visibilities}
              onChange={(value, action) => {
                handleSelectChange[action.action]('visibility', value);
                value && setValue('visibility', value.value);

                if (
                  !!value &&
                  value.value === 'public' &&
                  getValues('groups') !== undefined &&
                  getValues('groups').length
                ) {
                  setValue('groups', null);
                }
              }}
              value={visibilities.find(
                visibility => visibility.value === watch('visibility'),
              )}
              placeholder="Selecione a visibilidade do conteúdo"
              thereIsError={Object.keys(errors).includes('visibility')}
              isDisabled={view}
            />
          </TextField>
          {watch('visibility') === 'restricted' && (
            <TextField
              label="Grupos"
              name="groups"
              flex="1 0 13.75rem"
              required
            >
              <CustomSelect
                isMulti
                id="groups"
                isClearable
                isRtl={false}
                isSearchable
                options={groups}
                onChange={(values, action) => {
                  handleSelectChange[action.action]('groups', values);
                }}
                value={watch('groups')}
                placeholder="Selecione os grupos"
                thereIsError={Object.keys(errors).includes('group')}
                isDisabled={view}
              />
            </TextField>
          )}
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Data publicação início"
            name="published_start_at"
            flex="1 0 15rem"
            required
          >
            <DatePicker
              disablePast={verifyIfContentIsCreated}
              disabled={view}
              value={watch('published_start_at') || null}
              onChange={date => setValue('published_start_at', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Data publicação fim"
            name="published_end_at"
            flex="1 0 15rem"
            required
          >
            <DatePicker
              disablePast={verifyIfContentIsCreated}
              disabled={view}
              value={watch('published_end_at') || null}
              onChange={date => setValue('published_end_at', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
          <TextField label="Destaque" name="highlight" direction="row">
            <M.Checkbox
              id="highlight"
              checked={highlight}
              onChange={event => handleHighlight(event.target.checked)}
              disabled={view}
            />
          </TextField>

          {highlight && (
            <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
              <TextField
                label="Data Início Destaque"
                name="featured_start_at"
                flex="1 0 15rem"
              >
                <DatePicker
                  disabled={view}
                  value={watch('featured_start_at') || null}
                  onChange={date => setValue('featured_start_at', date)}
                  renderInput={params => <M.TextField {...params} />}
                />
              </TextField>
              <TextField
                label="Data Fim Destaque"
                name="featured_end_at"
                flex="1 0 15rem"
              >
                <DatePicker
                  disabled={view}
                  value={watch('featured_end_at') || null}
                  onChange={date => setValue('featured_end_at', date)}
                  renderInput={params => <M.TextField {...params} />}
                />
              </TextField>
            </FlexContainer>
          )}

          <TextField label="Data de Revisão" name="review_in" required>
            <DatePicker
              disabled={view}
              minDate={new Date()}
              value={watch('review_in') || null}
              onChange={date => setValue('review_in', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
          <TextField
            label="Unidade Solicitante"
            name="company_units"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="company_units"
              isMulti
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.company_units}
              onChange={(values, action) => {
                handleSelectChange[action.action]('company_units', values);
              }}
              value={watch('company_units')}
              placeholder="Selecione as unidades"
              thereIsError={Object.keys(errors).includes('company_units')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('companyUnits')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
          <TextField
            label="Nome Solicitante "
            name="requester"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="requester"
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.requesters}
              onChange={(value, action) => {
                handleSelectChange[action.action]('requester', value);
                value && setValue('requester_id', value.value);
              }}
              value={optionsToSelect.requesters.find(
                requester => requester.value === watch('requester_id'),
              )}
              placeholder="Selecione o solicitante"
              thereIsError={Object.keys(errors).includes('requester')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('requester')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
        </FlexContainer>

        <Categorization
          control={control}
          setValue={setValue}
          content={content}
          view={view}
        />

        <Authors
          clearErrors={clearErrors}
          setError={setError}
          control={control}
          setValue={setValue}
          content={content}
          view={view}
        />

        <SambaMainFile
          setValue={setValue}
          control={control}
          content={content}
          view={view}
          type={'lesson'}
          typeDuration={'course'}
          getValues={getValues}
        />

        <ContentList
          label="Conteúdos Complementares"
          view={view}
          setValue={setValue}
          control={control}
          content={content?.complement_contents}
          type={'lesson'}
        />

        <SeasonSection
          content={content}
          view={view}
          setValue={setValue}
          control={control}
        />
      </Form>

      <FlexContainer gap="0.875rem" flexWrap="wrap" justifyContent="center">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() =>
            handleSubmit(data => submit(data, 'published'), onError)()
          }
          disabled={view}
        >
          Publicar
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={() => handleSubmit(data => submit(data, 'draft'), onError)()}
          disabled={view}
        >
          Rascunho
        </Button>
        <Button
          type="button"
          variant="contained"
          color="info"
          onClick={() => {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: 'Tem certeza que deseja ocultar o curso?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
              buttonsStyling: false,
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn-ok',
                cancelButton: 'btn-cancel',
                title: 'modal-title',
                htmlContainer: 'modal-html-container',
              },
            }).then(async result => {
              if (result.isConfirmed) {
                handleSubmit(data => submit(data, 'hidden'), onError)();
              }
            });
          }}
          disabled={view}
        >
          Ocultar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onCancel}
          disabled={view}
          aria-disabled={view}
        >
          Cancelar
        </Button>
      </FlexContainer>

      {openModal.companyUnits &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.companyUnits}
            onCloseChange={() => handleOpenModal('companyUnits')}
            keyId={'company_unit_id'}
            funcCreate={createCompanyUnit}
            handleOptionChange={data =>
              handleMuiltSelected(data, 'company_units')
            }
            needSlug={false}
          />,
          document.body,
        )}
      {openModal.requester &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.requester}
            onCloseChange={() => handleOpenModal('requester')}
            keyId={'requester_id'}
            funcCreate={createRequest}
            handleOptionChange={(data: DataOptionType) => {
              const requesters = [...optionsToSelect.requesters];
              requesters.push({
                label: data.label,
                value: data.value,
                data: data.data,
              });

              handleSelected(data, 'requester');
              setValue('requester_id', data.value);
              setOptionsToSelect(prev => ({ ...prev, requesters }));
            }}
            needSlug={false}
          />,
          document.body,
        )}
    </Card>
  );
}
