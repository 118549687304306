/* eslint-disable no-useless-escape */

export function dealWithTitleChange(value: string) {
  let slugUrl: string = value;

  slugUrl = slugUrl.toLowerCase();
  slugUrl = slugUrl.replace(/ç/g, 'c');
  slugUrl = slugUrl.replace(/Ç/g, 'C');
  slugUrl = slugUrl.replace(/[áàãâä]/g, 'a');
  slugUrl = slugUrl.replace(/[éèêë]/g, 'e');
  slugUrl = slugUrl.replace(/[íìîï]/g, 'i');
  slugUrl = slugUrl.replace(/[óòõôö]/g, 'o');
  slugUrl = slugUrl.replace(/[úùûü]/g, 'u');
  slugUrl = slugUrl.replace(/[ÁÀÃÂÄ]/g, 'A');
  slugUrl = slugUrl.replace(/[ÉÈÊË]/g, 'E');
  slugUrl = slugUrl.replace(/[ÍÌÎÏ]/g, 'I');
  slugUrl = slugUrl.replace(/[ÓÒÕÔÖ]/g, 'O');
  slugUrl = slugUrl.replace(/[ÚÙÛÜ]/g, 'U');
  slugUrl = slugUrl.replace(/[ýÝÿŸ]+/g, 'y');
  slugUrl = slugUrl.replace(/[ñÑ]+/g, 'n');
  slugUrl = slugUrl.replace(/[çÇ]+/g, 'c');
  slugUrl = slugUrl.replace(/[ß]+/g, 'ss');
  slugUrl = slugUrl.replace(/[Ææ]+/g, 'ae');
  slugUrl = slugUrl.replace(/[Øøœ]+/g, 'oe');
  slugUrl = slugUrl.replace(/[%]+/g, 'pct');
  slugUrl = slugUrl.replace(/\s+/g, '-');
  slugUrl = slugUrl.replace(/[^\w\-]+/g, '');
  slugUrl = slugUrl.replace(/\-\-+/g, '-');
  slugUrl = slugUrl.replace(/^_+/, '');
  slugUrl = slugUrl.replace(/_/, '');
  slugUrl = slugUrl.replace(' ', '-');

  return slugUrl;
}
