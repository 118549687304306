import httpClient from '../http-client';
import { IEvent } from '../models/content';

export const getChannelsList = async () => {
  const channelList = (
    await httpClient.get<{ id: string; name: string }[]>(
      `/events/channels/list`,
    )
  ).data;

  return channelList;
};

export const startEvent = async (eventId: string) => {
  const event = (await httpClient.post<any>(`/events/start/${eventId}`)).data;

  return event;
};

export const stopEvent = async (eventId: string) => {
  const event = (await httpClient.post<any>(`/events/stop/${eventId}`)).data;

  return event;
};

export const getEventById = async (eventId: string) => {
  const event = (await httpClient.get<IEvent>(`/events/${eventId}`)).data;

  return event;
};
